import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Add, GetApp, RotateLeft } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../Components/Sidebar/SidebarButton';
import SidebarForm from '../../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import Sort, { SortItem } from '../../../Components/Sort';
import { AssessmentOrdersOverviewPerspective, UserRole } from '../../../system/Domain';
import { AssessmentOrdersOverviewQuery } from './AssessmentOrdersOverview';
import useUser from "../../../system/useUser";
import IndeterminateCheckbox from 'Components/IndeterminateCheckbox';

interface Props {
	query: AssessmentOrdersOverviewQuery
	setQuery: Dispatch<SetStateAction<AssessmentOrdersOverviewQuery>>
	resetQuery: () => AssessmentOrdersOverviewQuery
	canProcess: boolean
	isProcessing: boolean
	handleProcess: () => Promise<void>
	triggerExport: () => Promise<void>
}

const sortItems: SortItem[] = [
	{
		title: "Erstellungsdatum",
		field: "dateCreated"
	}
];

const getDateLabel = (perspective: AssessmentOrdersOverviewPerspective) => {
	switch (perspective) {
		case "Cancelled":
			return "abgebrochen seit dem";
		case "Completed":
			return "abgeschlossen seit dem";
		case "Processed":
			return "beauftragt seit dem";
		case "Own":
			return "erstellt seit dem";
		case "Unprocessed":
			return "erstellt seit dem";
		case "All":
			return "erstellt seit dem";
		case "Interrupted":
			return "unterbrochen seit dem";
	}
};

const AssessmentOrdersOverviewSidebar = (props: Props) => {
	const history = useHistory();
	const [, , hasRole] = useUser();

	const isAldUser = hasRole(UserRole.AldUser);

	const setQueryProperty = (property: string, newValue: boolean) => {
		props.setQuery(g => ({
			...g,
			[property]: newValue
		}));
	};

	return (
		<Sidebar>
			{isAldUser && (
				<SidebarGroup>
					<SidebarButton
						color="primary"
						startIcon={<Add />}
						label="Neuer Gutachtenauftrag"
						onClick={() => history.push("/orders/assessments/new")}
					/>
					<SidebarButton
						disabled={!props.canProcess || props.isProcessing}
						color="primary"
						onClick={props.handleProcess}
						useBusyWheelWhen={props.isProcessing}
					>
						Beauftragen
					</SidebarButton>
				</SidebarGroup>
			)}
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={props.query.perspective}
							onChange={(e) => props.setQuery(g => ({
								...g,
								skip: 0,
								perspective: e.target.value as AssessmentOrdersOverviewPerspective
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="Processed">Beauftragt</MenuItem>
							<MenuItem value="Unprocessed">Neu</MenuItem>
							<MenuItem value="Cancelled">Storniert</MenuItem>
							<MenuItem value="Completed">Abgeschlossen</MenuItem>
							<MenuItem value="Interrupted">Unterbrochen</MenuItem>
							<MenuItem value="Own">Von mir erstellt</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={props.query.sort}
						onChange={(sort) => props.setQuery(g => ({
							...g,
							skip: 0,
							sort
						}))}
					/>
					<FormControl fullWidth>
						<DatePicker
							format="DD.MM.YYYY"
							slotProps={{ textField: { variant: 'standard' } }}
							label={getDateLabel(props.query.perspective)}
							value={moment(props.query.dateFrom)}
							onChange={(d: any) => props.setQuery(q => ({
								...q,
								dateFrom: (d || moment()).startOf("d").toISOString(true)
							}))}
						/>
					</FormControl>
					{(props.query.perspective === "Processed" || props.query.perspective === "Cancelled" || props.query.perspective === "Completed") && (
						<FormControl fullWidth>
							<FormControlLabel
								control={<IndeterminateCheckbox value={props.query.isTransferred} set={(e) => { setQueryProperty('isTransferred', e) }} />}
								label="Übertragung durchgeführt"
							/>
						</FormControl>
					)}
					{props.query.perspective === "Interrupted" && (
						<>
							<FormControl fullWidth>
								<FormControlLabel
									control={<IndeterminateCheckbox value={props.query.hasInquiryRequest} set={(e) => { setQueryProperty("hasInquiryRequest", e) }} />}
									label="Sachverhaltsanfrage"
									color="warning"
								/>
							</FormControl>
							<FormControl fullWidth>
								<FormControlLabel
									control={<IndeterminateCheckbox value={props.query.hasDefect} set={(e) => { setQueryProperty("hasDefect", e) }} />}
									label="Mängelmeldung"
									color="warning"
								/>
							</FormControl>
						</>
					)}
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<Button
					variant="outlined"
					startIcon={<GetApp />}
					fullWidth
					color="inherit"
					disabled={props.isProcessing}
					onClick={props.triggerExport}
				>
					Excel-Export
				</Button>
				<Button
					variant="outlined"
					startIcon={<RotateLeft />}
					fullWidth
					color="inherit"
					onClick={props.resetQuery}
				>
					Filter zurücksetzen
				</Button>
			</SidebarGroup>
		</Sidebar>
	);
};

export default AssessmentOrdersOverviewSidebar;
