import SidebarForm from 'Components/Sidebar/SidebarForm';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { Add, Done, GetApp, RotateLeft } from '@mui/icons-material';
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import Sort, { SortItem } from 'Components/Sort';
import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import { VehicleServiceOverviewPerspective } from '../../system/Domain';
import useCompanies from '../../system/useCompanies';
import usePureCompanies from '../../system/usePureCompanies';
import useVehicleServiceTypes from '../../system/useVehicleServiceTypes';
import useVehicleServiceExport from './useVehicleServiceExport';
import useVehicleServicePermissions from './useVehicleServicePermissions';
import { VehicleServiceQuery } from './useVehicleServices';
import useCompounds from 'system/useCompounds';

interface Props {
	query: VehicleServiceQuery
	setQuery: Dispatch<SetStateAction<VehicleServiceQuery>>
	resetQuery: () => VehicleServiceQuery
}

const sortItems: SortItem[] = [
	{
		title: "Tätigkeit erstellt",
		field: "dateCreated"
	},
	{
		title: "Zugewiesen",
		field: "dateAssigned"
	},
	{
		title: "Umgesetzt",
		field: "dateCompleted"
	},
	{
		title: "Abgerechnet",
		field: "dateBilled"
	}
];

export default ({ query, setQuery, resetQuery }: Props) => {
	const [companies] = useCompanies();
	const [pureCompanies] = usePureCompanies();
	const [vehicleServiceServiceTypes] = useVehicleServiceTypes();
	const [exportState, triggerExport] = useVehicleServiceExport(query);
	const history = useHistory();
	const vehicleServicePermissions = useVehicleServicePermissions();
	const [compounds] = useCompounds();

	return (
		<Sidebar>
			<SidebarGroup>
				{vehicleServicePermissions.canCreate && (
					<SidebarButton
						color="primary"
						startIcon={<Add />}
						label="Tätigkeit anlegen"
						onClick={() => history.push("/vehicles/services/new")}
					/>
				)}
				{vehicleServicePermissions.canCloseAtAll && (
					<SidebarButton
						color="primary"
						startIcon={<Done />}
						label="Tätigkeiten abschließen"
						onClick={() => history.push("/vehicles/services/close/select-type")}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={query.perspective}
							onChange={(e) => setQuery(g => ({
								...g,
								skip: 0,
								compound: null,
								perspective: e.target.value as VehicleServiceOverviewPerspective
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="Unassigned">Nicht zugewiesen</MenuItem>
							<MenuItem value="Requested">Angefragt</MenuItem>
							<MenuItem value="Rejected">Abgewiesen</MenuItem>
							<MenuItem value="Unfinished">Offen</MenuItem>
							<MenuItem value="Started">Gestartet</MenuItem>
							<MenuItem value="Cancelled">Abgebrochen</MenuItem>
							<MenuItem value="Completed">Abgeschlossen</MenuItem>
							<MenuItem value="Billed">Abgerechnet</MenuItem>
							<MenuItem value="Own">Von mir erstellt</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={query.sort}
						onChange={sort => setQuery(q => ({
							...q,
							skip: 0,
							sort
						}))}
					/>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Dienstleister</InputLabel>
						<Select
							value={query.company || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;

								if (value === "-") {
									value = undefined;
								}

								setQuery(g => ({
									...g,
									skip: 0,
									company: value // as VehicleServiceServiceType
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							<Box my={2}>
								<Divider />
							</Box>
							{pureCompanies?.sort((a, b) => a.name.localeCompare(b.name)).map(c => (
								<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
							))}
							<Box my={2}>
								<Divider />
							</Box>
							{companies?.sort((a, b) => a.name.localeCompare(b.name))
								.filter(f => !pureCompanies?.some(pC => pC.name === f.name))
								.map(c => (
									<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControl fullWidth variant="standard">
						<InputLabel>Tätigkeit</InputLabel>
						<Select
							value={query.serviceTypeId || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;

								if (value === "-") {
									value = undefined;
								}

								setQuery(g => ({
									...g,
									skip: 0,
									serviceTypeId: value
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							<Box my={2}>
								<Divider />
							</Box>
							{vehicleServiceServiceTypes?.sort((a, b) => a.text.localeCompare(b.text)).map(t => (
								<MenuItem key={t.id} value={t.id}>{t.text}</MenuItem>
							))}
						</Select>
					</FormControl>

					{query.perspective !== "All" && (
						<FormControl fullWidth variant="standard" >
							<DatePicker
								//disableToolbar
								//variant="inline"
								format="DD.MM.YYYY"
								//margin="none"
								label="von"
								value={moment(query.dateFrom)}
								slotProps={{ textField: { variant: 'standard' } }}
								onChange={(d: any) => setQuery(q => ({
									...q,
									dateFrom: (moment(d) || moment()).startOf("d").toISOString(true)
								}))}
							/>
						</FormControl>
					)}
					{query.perspective !== "All" && (
						<FormControl fullWidth variant="standard">
							<DatePicker
								//disableToolbar
								// variant="standard"
								format="DD.MM.YYYY"
								//margin="none"
								label="bis"
								value={moment(query.dateTo)}
								slotProps={{ textField: { variant: 'standard' } }}
								onChange={(d: any) => setQuery(q => ({
									...q,
									dateTo: (moment(d) || moment()).startOf("d").toISOString(true)
								}))}
							/>
						</FormControl>
					)}
					<FormControl variant="standard" fullWidth>
						<InputLabel>Standort</InputLabel>
						<Select
							value={query.compound || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;

								if (value === "-") {
									value = undefined;
								}

								setQuery(g => ({
									...g,
									skip: 0,
									perspective: "Completed",
									compound: value
								}))
							}}
						>
							<MenuItem value={"-"}>
								Alle
							</MenuItem>
							{compounds?.map(c =>
								<MenuItem value={c.name} key={c.name}>
									{c.name}
								</MenuItem>
							)}
						</Select>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					label="Excel-Export"
					onClick={triggerExport}
					disabled={exportState.loading}
				/>
				<SidebarButton
					startIcon={<RotateLeft />}
					label="Filter zurücksetzen"
					onClick={resetQuery}
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
