import { Address, Compound, ContactInformation } from "../../../system/Domain";
import { Form } from "../../../system/useForm";
import React from "react";

const addressSetter = (key: string, form: Form, address: Address | undefined) => {
	if (!form || !address) return;

	form.setValue(`${key}.name`, address?.name?.trim() ?? "");
	form.setValue(`${key}.name1`, address?.name1?.trim() ?? "");
	form.setValue(`${key}.city`, address?.city?.trim() ?? "");
	form.setValue(`${key}.street`, address?.street?.trim() ?? "");
	form.setValue(`${key}.additionalInformation`, address?.additionalInformation?.trim() ?? "");
	form.setValue(`${key}.zipCode`, address?.zipCode?.trim() ?? "");
	form.setValue(`${key}.country`, address?.country?.trim() ?? "Deutschland");
};

const contactSetter = (key: string, form: Form, contact: ContactInformation | undefined) => {
	if (!form || !contact) return;

	form.setValue(`${key}.firstName`, contact?.firstName?.trim() ?? "");
	form.setValue(`${key}.lastName`, contact?.lastName?.trim() ?? "");
	form.setValue(`${key}.email`, contact?.email?.trim() ?? "");
	form.setValue(`${key}.telephone`, contact?.telephone?.trim() ?? "");
};

const setContactFromCompound = (key: string, form: Form, compound: Compound | undefined) => {
	if (!form || !compound) return;

	form.setValue(`${key}.firstName`, compound.address?.name?.trim() ?? " ");
	form.setValue(`${key}.lastName`, compound.address?.name1?.trim() ?? " ");
	form.setValue(`${key}.email`, compound.email?.trim() ?? " ");
	form.setValue(`${key}.telephone`, compound?.telephone?.trim() ?? " ");
};

const addressGetter = (key: string, form: Form): Address => {
	if (!form) return;

	return {
		name: form.getValue(`${key}.name`),
		name1: form.getValue(`${key}.name1`),
		city: form.getValue(`${key}.city`),
		street: form.getValue(`${key}.street`),
		additionalInformation: form.getValue(`${key}.additionalInformation`),
		zipCode: form.getValue(`${key}.zipCode`),
		country: form.getValue(`${key}.country`)
	};
};

const contactGetter = (key: string, form: Form): ContactInformation => {
	if (!form) return;

	return {
		firstName: form.getValue(`${key}.firstName`),
		lastName: form.getValue(`${key}.lastName`),
		email: form.getValue(`${key}.email`),
		telephone: form.getValue(`${key}.telephone`)
	}
};

const joinDisplay = (arr: (string | undefined)[]) => {
	return arr.filter((f) => !!f).join(" · ");
};

const joinNodes = (arr: (React.ReactNode | undefined)[]) => {
	const nonEmptyArray = arr.filter((f) => !!f);

	const result = nonEmptyArray.map((e, i) => (
		<React.Fragment key={i}>
			<span>{e}</span>
			{i < nonEmptyArray.length - 1 && <span> · </span>}
		</React.Fragment>
	));

	return result;
};

export { addressSetter, contactSetter, addressGetter, contactGetter, setContactFromCompound, joinDisplay, joinNodes };
