import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { AddAPhotoSharp, Menu } from '@mui/icons-material';
import { Box, Button, Fab, Grid, Typography, useTheme } from '@mui/material';

import Layout from '../../../Components/Layout/Layout';
import { StorageFile, Vehicle, VehiclePictureSet } from '../../../system/Domain';
import useAsyncEffect from '../../../system/useAsyncEffect';
import VehiclePicture from './VehiclePicture';
import VehiclePictureCache from './VehiclePictureCache';

export interface IVehiclePicture {
	ghostImageName: string
	filename: string | null
	uploadPromise: Promise<StorageFile> | null
	uploadProgress: number
}

export default () => {
	const [vehicle, setVehicle] = useState<Vehicle | null>(null);
	const [vehiclePictures, setVehiclePictures] = useState(VehiclePictureCache.initialValues);
	const [currentPictureIndex, setCurrentPictureIndex] = useState<number | null>(0);
	const { id } = useParams<{ id: string }>();
	const [stillUploading, setStillUploading] = useState<boolean>(false);
	const history = useHistory();
	const theme = useTheme();

	useAsyncEffect(async () => {
		const { data: vehicle } = await axios.get<Vehicle>(`/api/vehicles/${id}`);
		setVehicle(vehicle);
	}, [id]);

	const onDrop = useCallback(async acceptedFiles => {
		if (currentPictureIndex === null) return;
		if (acceptedFiles.length !== 1) return;
		const file = acceptedFiles[0];
		const formData = new FormData();
		formData.append("file", file);
		const currentPicture = vehiclePictures[currentPictureIndex];

		currentPicture.uploadPromise = axios.post<StorageFile>("/api/storage",
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: evt => {
					currentPicture.uploadProgress = Math.ceil((evt.loaded / evt.total) * 100);
				}
			}
		).then(async response => {
			VehiclePictureCache.put(currentPicture.ghostImageName, response.data);
			return response.data;
		});

		setVehiclePictures([
			...vehiclePictures.slice(0, currentPictureIndex),
			currentPicture,
			...vehiclePictures.slice(currentPictureIndex + 1),
		]);

		if (currentPictureIndex !== vehiclePictures.length - 1) {
			if (vehiclePictures[currentPictureIndex + 1].uploadPromise === null) {
				// if next picture in row is not yet to be uploaded, assume
				// we want to take all pictures in a row
				setCurrentPictureIndex(currentPictureIndex + 1);
			} else {
				// this displays the list of all pictures
				setCurrentPictureIndex(null);
			}
		} else {
			// at the end of the row, always display all pictures
			setCurrentPictureIndex(null);
		}

	}, [currentPictureIndex]);

	const handleUpload = async () => {
		if (!vehicle) return;

		const uploadNotFinished = new Promise<boolean>(resolve => process.nextTick(() => resolve(false)));
		const uploadsFinished = new Promise<boolean>(async (resolve) => {
			await Promise.all(vehiclePictures.map(p => p.uploadPromise));
			resolve(true);
		});

		const finished = await Promise.race([uploadsFinished, uploadNotFinished]);
		if (!finished) {
			setStillUploading(true);
			return;
		}

		// make sure, all uploads are finished before uploading the picture set
		const data = await Promise.all(vehiclePictures.map(p => p.uploadPromise));

		const vehicleSet = {
			frontLeft: data[0],
			backLeft: data[1],
			backRight: data[2],
			frontRight: data[3],
			headlights: data[4],
			tiresAndRims: data[5],
			interiorFront: data[6],
			interiorBack: data[7],
			trunk: data[8],
			cockpit: data[9],
			infotainment: data[10],
			instruments: data[11]
		};

		await axios.post<VehiclePictureSet>(`/api/vehicles/${vehicle.id}/picture-sets`, vehicleSet);
		VehiclePictureCache.clear();

		history.push(`/vehicles/${vehicle.id}/picture-sets`);
	};

	const handleClick = (index: number) => () => {
		setCurrentPictureIndex(index);
	};

	const handleShowListClicked = (event: React.MouseEvent) => {
		event.stopPropagation();
		setCurrentPictureIndex(null);
	};

	const handleClearMessage = () => {
		setStillUploading(false);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false
	});

	const currentPicture = currentPictureIndex !== null ? vehiclePictures[currentPictureIndex] : null;
	const allUploadsPresent = vehiclePictures.map(p => p.uploadPromise).every(p => p !== null);

	return (
		<>
			{currentPicture && (
				<div
					{...getRootProps()}
					style={{
						height: "100%",
						width: "100%",
						backgroundImage: `url('/static/${currentPicture.ghostImageName}')`,
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						opacity: 0.2
					}}>

					<Fab
						sx={{
							position: "fixed",
							top: theme.spacing(2),
							right: 0
						}}
						onClick={handleShowListClicked}
					>
						<Menu />
					</Fab>

					<input {...getInputProps()} capture />
					<AddAPhotoSharp sx={{
						position: "fixed",
						top: "50%",
						left: "50%",
						fontSize: "4rem",
						marginTop: "-2rem",
						marginLeft: "-2rem"
					}} />
				</div>
			)}

			{!currentPicture && (
				<Layout>
					<Box m={2}>
						<Box my={2}>
							{stillUploading && (
								<Box my={2}>
									<Typography>Es sind noch nicht alle Bilder hochgeladen</Typography>
									<Button variant="contained" onClick={handleClearMessage}>OK</Button>
								</Box>
							)}
							{!stillUploading && (
								<Button
									variant="contained"
									disabled={!allUploadsPresent}
									onClick={handleUpload}
								>
									Hochladen
								</Button>
							)}
						</Box>
						<Grid container spacing={2}>
							{vehiclePictures.map((picture, i) => (
								<Grid item key={i}
									sx={{
										height: 180,
										width: 270
									}}>
									<VehiclePicture
										picture={picture}
										onClick={handleClick(i)}
									/>
								</Grid>
							))}
						</Grid>
					</Box>
				</Layout>
			)}
		</>
	);
}
