import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useHotkeys from "system/useHotkeys";
import { ExitToApp, Group } from "@mui/icons-material";
import { Pagination } from "@mui/lab";
import { alpha, Box, Button, darken, Divider, Grid, IconButton, Input, lighten, Paper, useTheme } from "@mui/material";
import { WorkItem, WorkSession, WorkSessionUserNavigation } from "../../system/Domain";
import formatNumber from "../../system/formatNumber";
import { clearWorkSession, setWorkSession } from "../../system/reducers/workSessions/actions";
import { AppState } from "../../system/store";
import useAsyncEffect from "../../system/useAsyncEffect";
import useChannel from "../../system/useChannel";
import useEvent from "../../system/useEvent";
import useUser from "../../system/useUser";

const resolveUrl = (item: WorkItem) => {
	switch (item.type) {
		case "Vehicle":
			return `/vehicles/${item.id}/orders`;
		case "FailedCarmarketPublishAttempts":
		case "Sale":
			return `/sales/${item.id}`;
		case "Presale":
			return `/vehicles/${item.id}/remarketing`;
		case "PricingRequest":
			return `/sales/pricing-requests/${item.id}`;
		case "DirectSale":
			return `/sales/${item.id}/direct-sales`;
		case "DocumentHandover":
			return `/vehicles/${item.id}/registration-documents/handovers`;
		case "VehicleRepairOrder":
			return `/vehicles/${item.id}/orders/repairs`;
		case "AssessmentOrder": // there is no assessment order dedicated view, so we open vehicle all orders
			return `/vehicles/${item.id}/orders`;
		default:
			return null;
	}
};

export interface WorkSessionParticipant {
	update: WorkSessionUserNavigation;
	dateFirstSeen: string;
	dateLastSeen: string;
}

export default () => {
	const theme = useTheme();
	const [appUser] = useUser();
	const dispatch = useDispatch();
	const history = useHistory();

	const session = useSelector((state: AppState) => state.workSessions.workSession);
	const [current, setCurrent] = useState(0);
	const [participants, setParticipants] = useState<WorkSessionParticipant[]>([]);
	const [pagingValue, setPagingValue] = useState<string>("");

	const updateParticipant = (update: WorkSessionUserNavigation) => {
		const existingParticipant = participants.find((p) => p.update.user.id === update.user.id);
		const participant: WorkSessionParticipant = {
			update,
			dateFirstSeen: existingParticipant?.dateFirstSeen || moment().toISOString(),
			dateLastSeen: moment().toISOString(),
		};

		setParticipants((p) => [...p.filter((p) => p.update.user.id !== update.user.id), participant]);
	};

	useChannel(session?.id);

	useEffect(() => {
		if (!session || !appUser) {
			setCurrent(0);
			return;
		}

		setCurrent(session.current);

		updateParticipant({
			sessionId: session.id,
			user: appUser,
			current: session.current,
			url: history.location.pathname,
			date: moment().toISOString(),
		});

		sendUserUpdate(session.current);
	}, [session?.id]);

	useEvent(
		"work-session:user-navigation",
		(data) => {
			const update = data as WorkSessionUserNavigation;

			if (!update || !session || !appUser || update.sessionId !== session.id) {
				return;
			}

			const fromCreator = update.user.id === session.createdBy.id;
			const fromMe = appUser.id === update.user.id;

			let newCurrent = current;
			if (fromCreator || fromMe) {
				setCurrent(update.current);
				newCurrent = update.current;
			}

			if (!fromMe && fromCreator) {
				sendUserUpdate(newCurrent);
			}

			updateParticipant(update);
		},
		[appUser, session],
	);

	useEvent(
		"work-session:closed",
		(data) => {
			const { id } = data;

			if (!session || !id || session.id !== id) {
				return;
			}

			closeWorkSession();
		},
		[session],
	);

	useEffect(() => {
		if (session && !current) {
			setCurrent(session.current);
		}
	}, [session?.current]);

	const getUrl = (current: number) => {
		const item = session?.items.find((i) => i.nr === current);
		if (item) {
			return resolveUrl(item);
		}
		return null;
	};

	const currentUrl = getUrl(current);
	const [browserUrl, setBrowserUrl] = useState(history.location.pathname);

	const navigate = (current: number) => {
		const url = getUrl(current);
		if (url && url !== history.location.pathname) {
			setBrowserUrl(url);
			history.push(url);
		}
	};

	useEffect(() => {
		if (history.location.pathname === browserUrl) {
			return;
		}

		setBrowserUrl(history.location.pathname);
	}, [history.location.pathname]);

	const sessionId = sessionStorage["work-session"];
	useAsyncEffect(async () => {
		if (!session && !!sessionId) {
			const { data: workSession } = await axios.get<WorkSession>(`/api/work-sessions/${sessionId}`);
			dispatch(setWorkSession(workSession));
		}
	}, [session, sessionId]);

	useHotkeys(
		"shift+left, ctrl+h",
		(event, handler) => {
			console.log(event, handler);
			sendUserUpdate(Math.max(1, current - 1));
			event.preventDefault();
			return false;
		},
		[current],
	);

	useHotkeys("shift+right, ctrl+l", () => sendUserUpdate(Math.min(session?.items.length ?? 0, current + 1)), [
		current,
		session?.items.length,
	]);

	const sendUserUpdate = async (current: number) => {
		setCurrent(current);
		navigate(current);

		await axios.post<WorkSession>(`/api/work-sessions/${session!.id}/user`, {
			current,
			url: document.location.pathname,
		});
	};

	const closeWorkSession = async () => {
		dispatch(clearWorkSession());

		if (session!.createdBy.id === appUser?.id) {
			await axios.post<WorkSession>(`/api/work-sessions/${session!.id}/close`);
		}
	};

	const confirmDirectNavigation = async (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (e.key !== "Enter") return;

		const numericInput = parseInt(pagingValue, 10);

		if (isNaN(numericInput) || numericInput <= 0) {
			setPagingValue("");

			return;
		}

		setPagingValue("");
		await sendUserUpdate(Math.min(numericInput, session!.items.length));
	};

	if (!session) {
		return null;
	}

	return (
		<Box
			sx={{
				position: "absolute",
				bottom: theme.spacing(2),
				zIndex: 1000,
				left: "50%",
				transform: "translate(-50%,0%)",
			}}
		>
			<Paper
				sx={{
					padding: theme.spacing(1),
					backgroundColor:
						theme.palette.mode === "dark"
							? alpha(darken(theme.palette.background.paper, 0.2), 0.95)
							: alpha(lighten(theme.palette.primary.light, 0.75), 0.9),
				}}
			>
				<Grid container spacing={1} alignItems="center">
					<Grid item>
						<Pagination
							size="large"
							page={current}
							count={session.items.length}
							boundaryCount={1}
							siblingCount={1}
							sx={{
								"& .MuiPaginationItem-page.Mui-selected": {
									backgroundColor: alpha(theme.palette.primary.light, 0.5),
								},
								...(currentUrl !== browserUrl && {
									"& .MuiPaginationItem-page.Mui-selected": {
										backgroundImage:
											"repeating-linear-gradient(135deg, rgba(0, 0, 0, 0.2) 10px, rgba(0, 0, 0, 0.2) 10px, transparent 12px, transparent 20px)",
										backgroundColor: alpha(theme.palette.error.light, 0.3),
									},
								}),
							}}
							onChange={(e, p) => sendUserUpdate(p)}
						/>
					</Grid>
					<Grid
						item
						sx={{
							alignSelf: "stretch",
						}}
					>
						<Divider orientation="vertical" />
					</Grid>

					<Grid item>
						<Box ml={2} width="40px">
							<Input
								type="tel"
								value={pagingValue}
								fullWidth
								inputProps={{ style: { textAlign: "center" } }}
								placeholder={current.toString()}
								onChange={(e) => setPagingValue(e.target.value)}
								onKeyDown={confirmDirectNavigation}
							/>
						</Box>
					</Grid>

					<Grid item>
						<Button variant="text" color="primary" size="small" endIcon={<Group />}>
							{formatNumber(participants.length)}
						</Button>
					</Grid>

					<Grid item>
						<IconButton color="default" size="medium" onClick={closeWorkSession}>
							<ExitToApp />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};
