import React from "react";
import { Box, Chip, TableBody, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import TextBlock from "../../Components/Layout/TextBlock";
import * as colors from "@mui/material/colors";
import PaperTable from "../../Components/PaperTable";
import { VehicleService } from "../../system/Domain";
import { useHistory } from "react-router";
import { Plate } from "../../Components/Plate";
import dateFormats from "../../system/dateFormats";
import ActionButton from "../../Components/ActionButton";
import axios from "axios";
import useVehicleServicePermissions from "./useVehicleServicePermissions";
import formatCurrency from "../../system/formatCurrency";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

interface Props {
	vehicleServices: VehicleService[]
	setVehicleServices: React.Dispatch<React.SetStateAction<VehicleService[]>>
	showVehiclePlate?: boolean
}

const statusChip = (service: VehicleService) => {
	switch (service.status) {
		case "Requested":
			return (
				<TextBlock
					chip={{
						label: "Angefragt",
						color: "secondary"
					}}
					secondary={moment(service.dateCreated).format(dateFormats.dateTime)}
				/>
			)
		case "Cancelled":
			return (
				<TextBlock
					chip={{
						label: "Abgebrochen",
						explicitColor: colors.orange[500]
					}}
					secondary={moment(service.dateCancelled).format(dateFormats.dateTime)}
				/>
			)
		case "Rejected":
			return (
				<TextBlock
					chip={{
						label: "Abgewiesen",
						explicitColor: colors.red[500]
					}}
					secondary={moment(service.dateRejected).format(dateFormats.dateTime)}
				/>
			)
		case "Started":
			return (
				<TextBlock
					chip={{
						label: "Gestartet",
						color: "secondary"
					}}
					secondary={moment(service.dateStarted).format(dateFormats.dateTime)}
				/>
			)
		case "Completed":
			return (
				<TextBlock
					chip={{
						label: "Abgeschlossen",
						explicitColor: colors.green[500]
					}}
					secondary={moment(service.dateCompleted).format(dateFormats.dateTime)}
				/>
			)
		case "Billed":
			return (
				<TextBlock
					chip={{
						label: "Abgerechnet",
						explicitColor: colors.blue[500]
					}}
					secondary={<span>{moment(service.dateBilled).format(dateFormats.dateTime)} · {service.billingClient}</span>}
				/>
			)
		default:
			return (
				<TextBlock
					chip={{
						label: "Offen",
						color: "secondary"
					}}
					secondary={moment(service.dateAssigned).format(dateFormats.dateTime)}
				/>
			)
	}
};

const compoundChip = (service: VehicleService) => {
	if (service.status !== "Completed") return undefined;

	return (
		<TextBlock
			chip={{ label: service.compound?.name }}
		/>
	);
};

const companyChip = (service: VehicleService) => {
	return (
		<Chip
			label={service.assignedTo?.name ?? "Kein Dienstleister zugewiesen"}
		/>
	);
};

export default ({ vehicleServices, setVehicleServices, showVehiclePlate }: Props) => {
	const history = useHistory();
	const vehicleServicePermissions = useVehicleServicePermissions();

	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				{!vehicleServicePermissions.isRestrictedToHisCompany && (
					<col width="auto" />
				)}
				{showVehiclePlate && (
					<col width="auto" />
				)}
				<col width="auto" />
				<col width="auto" />
				{vehicleServicePermissions.canSeePrices && (
					<col width="auto" />
				)}
				<col width="auto" />
			</Colgroup>
			<TableHead>
				<TableRow>
					{!vehicleServicePermissions.isRestrictedToHisCompany && (
						<TableCell sm md lg xl>Dienstleister</TableCell>
					)}
					{showVehiclePlate && (
						<TableCell sm md lg xl>Fahrzeug</TableCell>
					)}
					<TableCell>Tätigkeit</TableCell>
					<TableCell xl>Status</TableCell>
					{vehicleServicePermissions.canSeePrices && (
						<TableCell xl>Preis</TableCell>
					)}
					<TableCell xl>Standort</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{vehicleServices && vehicleServices.map(service => {
					const title = (
						<TextBlock
							chip={{
								label: service.text
							}}
							secondary={moment(service.dateCompleted || service.dateCreated).format(dateFormats.dateTime)}
						/>
					);

					const plate = <>
						{service.plateNumber && (
							<Plate plateNumber={service.plateNumber} style={{ zoom: 0.6 }} />
						)}
						{service.vehicle && (
							<TextBlock
								primary={service.vehicle.type?.description}
								secondary={service.vin}
								compact
							/>
						)}
					</>;

					const amount = (
						<TextBlock
							primary={formatCurrency(service.amount)}
							compact
						/>
					);

					const confirmButton = vehicleServicePermissions.canConfirmRequests && service.status === "Requested" && (
						<ActionButton variant="text" onClick={async (e) => {
							e.preventDefault();
							e.stopPropagation();

							const { data: vehicleService } = await axios.post(`/api/vehicles/services/${service.id}/confirm`);

							setVehicleServices(services => services
								.map(s => s.id === vehicleService.id ? vehicleService : s)
							);
						}}>
							Bestätigen
						</ActionButton>
					);

					return (
						<TableRow
							key={service.id}
							hover
							onClick={() => history.push(`/vehicles/${service.vehicleId}/services/${service.id}`)}
						>
							{!vehicleServicePermissions.isRestrictedToHisCompany && (
								<TableCell sm md lg xl>
									{companyChip(service)}
								</TableCell>
							)}
							{showVehiclePlate && (
								<TableCell sm md lg xl>
									{plate}
								</TableCell>
							)}
							<TableCell>
								{!vehicleServicePermissions.isRestrictedToHisCompany && (
									<Box sx={{ display: { xs: "contents", sm: "none" } }}>
										<Box mb={2}>
											{companyChip(service)}
										</Box>
									</Box>
								)}
								{showVehiclePlate && (
									<Box sx={{ display: { xs: "contents", sm: "none" } }}>
										<Box mb={2}>
											{plate}
										</Box>
									</Box>
								)}
								{title}
								<Box sx={{ display: { xs: "contents", xl: "none" } }}>
									<Box mt={2}>
										{statusChip(service)}
										<Box mt={2}>
											{compoundChip(service)}
										</Box>
									</Box>
									{vehicleServicePermissions.canConfirmRequests && service.status === "Requested" && (
										<Box mt={2}>
											{confirmButton}
										</Box>
									)}
									{vehicleServicePermissions.canSeePrices && (
										<Box mt={2}>
											{amount}
										</Box>
									)}
								</Box>
							</TableCell>
							<TableCell xl>
								{statusChip(service)}
								{vehicleServicePermissions.canConfirmRequests && service.status === "Requested" && (
									<Box mt={2}>
										{confirmButton}
									</Box>
								)}
							</TableCell>

							{vehicleServicePermissions.canSeePrices && (
								<TableCell xl>
									{amount}
								</TableCell>
							)}
							<TableCell xl>
								{compoundChip(service)}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</PaperTable>
	);
}
